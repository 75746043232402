import { Auth } from 'aws-amplify'

export async function getIdToken() {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  const jwtToken = cognitoUser?.signInUserSession?.idToken?.jwtToken
  return jwtToken
}

export async function getIdentityId() {
  const info = await Auth.currentCredentials()
  return info.identityId
}
