import { createReducer } from '../../utils/redux'

import { SUPPLIER_FETCHED } from '../../actions/supplier'

const initialState = {
  supplier: {}
}

const handler = {
  [SUPPLIER_FETCHED]: (state, action) => ({
    ...state,
    supplier: !action.error ? action.supplier : {}
  })
}

export const supplierReducer = createReducer(handler, initialState)
