import { createReducer } from '../../utils/redux'

import {
  SET_ERRORS,
  CLEAR_ERRORS,
  SET_SUCCESS,
  CLEAR_SUCCESS,
  WEB_SERIAL_NOT_SUPPORTED
} from '../../actions/util'

import { FETCH_INIT, INIT_FETCHED } from '../../actions/init'

const initialState = {
  errors: [],
  success: '',
  loading: false,
  webSerialNotSupported: false,
  initSuccess: false
}
const handler = {
  [SET_ERRORS]: (state, action) => ({
    ...state,
    errors:
      typeof action.errorMessages === 'string'
        ? [action.errorMessages]
        : action.errorMessages
  }),
  [CLEAR_ERRORS]: state => ({
    ...state,
    errors: []
  }),
  [SET_SUCCESS]: (state, action) => ({
    ...state,
    errors: [],
    success: action.successMessage
  }),
  [CLEAR_SUCCESS]: state => ({
    ...state,
    success: ''
  }),
  [FETCH_INIT]: state => ({
    ...state,
    loading: true
  }),
  [INIT_FETCHED]: (state, action) => ({
    ...state,
    loading: false,
    initSuccess: action.isSuccess
  }),
  [WEB_SERIAL_NOT_SUPPORTED]: (state, action) => ({
    ...state,
    webSerialNotSupported: action.value
  })
}

export const utilReducer = createReducer(handler, initialState)
