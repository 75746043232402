import { Fragment } from 'react'
import { I18n } from 'aws-amplify'

const insertIcon = isDark => {
  return (
    <svg
      className='animate-spin h-9 w-9 text-black m-2'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
    >
      <circle
        className={`opacity-25 ${isDark ? 'text-white' : 'text-slate-700'}`}
        cx='12'
        cy='12'
        r='10'
        stroke='currentColor'
        strokeWidth='4'
      ></circle>
      <path
        className={`opacity-75 ${isDark ? 'text-white' : 'text-slate-700'}`}
        fill='currentColor'
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      ></path>
    </svg>
  )
}

const insertText = text => {
  return <p className='text-sm'>{text}</p>
}

const baseContainerClass =
  'absolute flex justify-center flex-col gap-2 items-center h-screen w-full backdrop-blur-sm top-0 left-0 z-10 '
const lightContainerClass = `${baseContainerClass} text-slate-700 bg-white/70`
const darkContainerClass = `${baseContainerClass} text-white bg-slate-800/90`

const Loader = ({
  fullpage = true,
  text = null,
  className = '',
  isDark = false
}) => {
  return (
    <Fragment>
      {fullpage && (
        <div
          className={`${className} ${
            isDark ? darkContainerClass : lightContainerClass
          }`}
        >
          {insertIcon(isDark)}
          {insertText(text || I18n.get('Loading'))}
        </div>
      )}
      {!fullpage && (
        <Fragment>
          <div
            className={`flex justify-center flex-col gap-2 items-center ${className}`}
          >
            {insertIcon(isDark)}
            {text && insertText(text)}
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default Loader
