import { UI_AUTH_CHANNEL } from '@aws-amplify/ui-components'
import { Hub } from 'aws-amplify'
import { useState } from 'react'

function useAuthChannel() {
  const [state, setState] = useState({})

  Hub.listen(UI_AUTH_CHANNEL, ({ payload }) => {
    setState(payload)
  })

  return [state, UI_AUTH_CHANNEL]
}

export default useAuthChannel
