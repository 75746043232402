import { Amplify } from 'aws-amplify'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import Routes from './routes'

import { AuthProvider } from './contexts/auth-context'
import configureStore from './store'

import './index.css'

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'main',
        endpoint: process.env.REACT_APP_ENDPOINT
      }
    ]
  }
})

const store = configureStore()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <AuthProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Provider>
  </AuthProvider>
)
