import { I18n } from 'aws-amplify'
import { string, object, ref } from 'yup'

const codeRequired = I18n.get(
  'Please enter the code sent to your email address.'
)
const emailRequired = I18n.get('Please enter your email address.')
const passwordRequired = I18n.get('Please enter your password.')
const oldPasswordRequired = I18n.get('Please enter your old password.')
const passwordConfirmRequired = I18n.get('Please confirm your password.')
const passwordsMustMatch = I18n.get('Your passwords do not match.')
const passwordMinLength = I18n.get(
  'Password must be at least 6 characters long.'
)
const passwordMaxLength = I18n.get('Password must be under 99 characters long.')
const passwordLowercase = I18n.get(
  'Password must contain at least one lowercase character.'
)
const passwordUppercase = I18n.get(
  'Password must contain at least one uppercase character.'
)
const passwordNumber = I18n.get('Password must contain at least one number.')
const passwordSpecialChar = I18n.get(
  'Password must contain a special character: ^$*.[]{}()?"!@#%&/\\,><\':;|_~`=+-'
)

export const signInSchema = object().shape({
  username: string().required(emailRequired),
  password: string().required(passwordRequired)
})

export const changePasswordSchema = object().shape({
  code: string().required(codeRequired),
  oldPassword: string().required(oldPasswordRequired),
  newPassword: string()
    .required(passwordRequired)
    .min(6, passwordMinLength)
    .max(99, passwordMaxLength)
    .matches(/(?=.*[a-z])/, passwordLowercase)
    .matches(/(?=.*[A-Z])/, passwordUppercase)
    .matches(/(?=.*[0-9])/, passwordNumber)
    .matches(
      /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/,
      passwordSpecialChar
    ),
  confirmPassword: string()
    .required(passwordConfirmRequired)
    .oneOf([ref('newPassword')], passwordsMustMatch)
})

export const passwordSchema = object().shape({
  newPassword: string()
    .required(passwordRequired)
    .min(6, passwordMinLength)
    .max(99, passwordMaxLength)
    .matches(/(?=.*[a-z])/, passwordLowercase)
    .matches(/(?=.*[A-Z])/, passwordUppercase)
    .matches(/(?=.*[0-9])/, passwordNumber)
    .matches(
      /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/,
      passwordSpecialChar
    ),
  confirmPassword: string()
    .required(passwordConfirmRequired)
    .oneOf([ref('newPassword')], passwordsMustMatch)
})

export const forgotPasswordSchema = object().shape({
  code: string().required(codeRequired),
  newPassword: string()
    .required(passwordRequired)
    .min(6, passwordMinLength)
    .max(99, passwordMaxLength)
    .matches(/(?=.*[a-z])/, passwordLowercase)
    .matches(/(?=.*[A-Z])/, passwordUppercase)
    .matches(/(?=.*[0-9])/, passwordNumber)
    .matches(
      /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/,
      passwordSpecialChar
    ),
  confirmPassword: string()
    .required(passwordConfirmRequired)
    .oneOf([ref('newPassword')], passwordsMustMatch)
})

export const codeSchema = object().shape({
  code: string().required(codeRequired)
})

export const sendResetLinkSchema = object().shape({
  username: string().required(emailRequired)
})
