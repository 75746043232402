import { I18n } from 'aws-amplify'

import { getUsers } from '../../reducers/selectors'

export default function Table({ onSelectUser }) {
  const users = getUsers()

  return (
    <div className='mt-8 flow-root'>
      <div className='-my-2 overflow-x-auto'>
        <div className='inline-block min-w-full py-2 align-middle'>
          <table className='min-w-full divide-y divide-gray-300 px-4'>
            <thead>
              <tr>
                <th
                  scope='col'
                  className='text-left text-sm font-semibold text-gray-900 pb-2'
                >
                  {I18n.get('Email')}
                </th>
                <th scope='col' className='relative'>
                  <span className='sr-only'>{I18n.get('Edit')}</span>
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {users.map(user => (
                <tr key={user.userId}>
                  <td className='whitespace-nowrap py-4 text-sm text-gray-500'>
                    {user.email}
                  </td>
                  <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8'>
                    <button
                      className='text-red-600 hover:text-red-900'
                      onClick={() => onSelectUser(user)}
                    >
                      {I18n.get('Delete')}
                      <span className='sr-only'>, {user.email}</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
