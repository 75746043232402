import { I18n } from 'aws-amplify'
import { QRCodeCanvas } from 'qrcode.react'

import IconBlackSvg from '../../assets/icon-black.svg'

const WEBAPP_DOMAIN_NAME = process.env.REACT_APP_WEBAPP_DOMAIN_NAME

const QRCode = ({ device }) => {
  const qrCodeValue = `https://${WEBAPP_DOMAIN_NAME}/enroll-device/${
    device.deviceId ?? 'wrong-id'
  }`

  return (
    <div
      id='qr-code'
      style={{ height: '170px' }}
      className='border-4 border-gray-400 rounded-md flex items-center flex-start gap-4 px-4'
    >
      <QRCodeCanvas value={qrCodeValue} size={120} id='qr-code' />
      <div className='flex flex-col'>
        <img
          className='h-14 w-auto text-black'
          src={IconBlackSvg}
          alt='Coretex'
        />
        <p className='text-base text-black'>
          <span className='font-bold'>{I18n.get('ID')}:</span>{' '}
          {device?.deviceId ?? 'fake-id'}
        </p>
        <p className='text-base text-black'>
          <span className='font-bold'>{I18n.get('Name')}:</span>{' '}
          {device?.name ?? 'fake-name'}
        </p>
        <p className='text-base text-black'>
          <span className='font-bold'>{I18n.get('Type')}:</span>{' '}
          {device?.type ?? 'fake-type'}
        </p>
      </div>
    </div>
  )
}

export default QRCode
