import { Fragment, useState } from 'react'
import Actions from '../components/Users/Actions'
import AddModal from '../components/Users/AddModal'
import DeleteModal from '../components/Users/DeleteModal'
import Table from '../components/Users/Table'

function Users() {
  const [addUserOpen, setAddUserOpen] = useState(false)
  const [deleteUserOpen, setDeleteUserOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  function onAddUser() {
    setAddUserOpen(true)
  }

  function onCloseAddModal() {
    setAddUserOpen(false)
  }

  function onCloseDeleteModal() {
    setDeleteUserOpen(false)
    setSelectedUser(null)
  }

  function onSelectUser(user) {
    setSelectedUser(user)
    setDeleteUserOpen(true)
  }

  return (
    <Fragment>
      <Actions onAddUser={onAddUser} />
      <Table onSelectUser={onSelectUser} />
      {addUserOpen && <AddModal onCloseModal={onCloseAddModal} />}
      {deleteUserOpen && (
        <DeleteModal user={selectedUser} onCloseModal={onCloseDeleteModal} />
      )}
    </Fragment>
  )
}

export default Users
