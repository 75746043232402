import { useCallback, useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PlusIcon } from '@heroicons/react/20/solid'
import { updateNvsPartition } from '../../../actions/device'

const ACTION_TYPES = {
  PAR_REF_OFFSET: 'parRefOffset',
  SLEEP_CYCLE: 'sleepCycle',
  APN: 'apn',
  RESET: 'reset'
}

const sleepCycle = ['sleepCycle', 'data', 'u8', '15'];
const CUSTOM_FIELDS = {
  envirosense: {
    parRefOffset: ['parRefOffset', 'data', 'string', '0.03865']
  },
  cellular_envirosense: {
    parRefOffset: ['parRefOffset', 'data', 'string', '0.03865'],
    apn: ['APN', 'data', 'string', 'TM'],
    sleepCycle
  },
  cellular_tubular_soilsense: { sleepCycle },
  cellular_soilsense: { sleepCycle },
}

const initialState = {
  parRefOffset: '0.03865',
  apn: 'TM',
  sleepCycle: '15'
}

function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.PAR_REF_OFFSET:
      return { ...state, parRefOffset: action.value }
    case ACTION_TYPES.SLEEP_CYCLE:
      return { ...state, sleepCycle: action.value }
    case ACTION_TYPES.APN:
      return { ...state, apn: action.value }
    case ACTION_TYPES.RESET:
      return initialState
    default:
      return state
  }
}

function CustomParRefOffset({ value, onChange }) {
  return (
    <input
      type='text'
      id='parRefOffset'
      name='parRefOffset'
      className='mt-1 focus:ring-teal-500 focus:border-teal-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
      placeholder='0.03865'
      onChange={onChange}
      value={value}
    />
  )
}

function CustomApn({ value, onChange }) {
  return (
    <select value={value} onChange={onChange}>
      <option value='TM'>TM</option>
      <option value='soracom.io'>Soracom</option>
    </select>
  )
}

function CustomSleepCycle({ value, onChange }) {
  const values = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
  return (
    <select value={value} onChange={onChange}>
      {values.map(v => (<option key={v} value={v}>{v}</option>))}
    </select>
  )
}

export default function NvsPartitionTemplate({ selectedType, nvsPartition }) {
  const reduxDispatch = useDispatch()

  const [customFields, setCustomFields] = useState({})
  const [usedCustomFieldsKeys, setUsedCustomFieldsKeys] = useState([])
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    setCustomFields(CUSTOM_FIELDS[selectedType?.id] || {})
    setUsedCustomFieldsKeys([])
    dispatch({ type: ACTION_TYPES.RESET })
  }, [selectedType?.id])

  function onAddNewValue(e) {
    e.preventDefault()
    const keys = Object.keys(customFields)
    const unusedKeys = keys.filter(key => !usedCustomFieldsKeys.includes(key))
    if (unusedKeys.length > 0) {
      const unusedKey = unusedKeys[0]
      reduxDispatch(
        updateNvsPartition([...nvsPartition, customFields[unusedKey]])
      )
      setUsedCustomFieldsKeys([...usedCustomFieldsKeys, unusedKey])
    }
  }

  function updateNvsPartitionField(field, value) {
    dispatch({ type: field, value })
    let nextNvsPartition = [...nvsPartition]

    let fieldValues = customFields[field]
    fieldValues[3] = value

    const index = nvsPartition.findIndex(item => item[0] === field)
    if (index > -1) {
      nextNvsPartition[index] = fieldValues
      reduxDispatch(updateNvsPartition(nextNvsPartition))
    }
  }

  const onChangeParRefOffset = useCallback((e) => updateNvsPartitionField('parRefOffset', e.target.value), [updateNvsPartitionField])
  const onChangeApn = useCallback((e) => updateNvsPartitionField('apn', e.target.value), [updateNvsPartitionField])
  const onChangeSleepCycle = useCallback((e) => updateNvsPartitionField('sleepCycle', e.target.value), [updateNvsPartitionField])

  if (nvsPartition?.length === 0) {
    return null
  }

  const headers = nvsPartition[0]
  const items = nvsPartition.slice(1)

  const customFieldsKeys = Object.keys(customFields)

  return (
    <div className='mt-6'>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <p className='text-sm font-extrabold'>Configurable NVS</p>
          <p className='mt-2 text-sm text-gray-700'>
            A list of all values for NVS partition.
          </p>
        </div>

        <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
          {customFieldsKeys.length > 0 &&
            customFieldsKeys.length > usedCustomFieldsKeys.length && (
              <button
                type='button'
                className='px-4 py-2 mt-4 bg-teal-400 text-black rounded hover:bg-teal-500 font-bold text-sm flex gap-2 justify-center'
                onClick={onAddNewValue}
              >
                <PlusIcon className='h-5 w-5 self-center' /> Add new value
              </button>
            )}
        </div>
      </div>
      <div className='mt-1 flow-root'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead>
                <tr>
                  {headers.map(header => (
                    <th
                      key={header}
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-0'
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                {items.map(item => {
                  const [key, type, encoding, value] = item
                  return (
                    <tr key={key}>
                      <td className='whitespace-nowrap py-3 pl-4 pr-3 text-sm font-bold text-gray-500 sm:pl-0'>
                        {key}
                      </td>
                      <td className='whitespace-nowrap pr-3 py-3 text-sm text-gray-500'>
                        {type}
                      </td>
                      <td className='whitespace-nowrap pr-3 py-3 text-sm text-gray-500'>
                        {encoding}
                      </td>
                      <td className='whitespace-nowrap pr-3 py-3 text-sm text-gray-500'>
                        {key === 'parRefOffset' && (
                          <CustomParRefOffset
                            value={state.parRefOffset}
                            onChange={onChangeParRefOffset}
                          />
                        )}
                        {key === 'APN' && (
                          <CustomApn value={state.apn} onChange={onChangeApn} />
                        )}
                        {key === 'sleepCycle' && (
                          <CustomSleepCycle value={state.sleepCycle} onChange={onChangeSleepCycle} />
                        )}
                        {key !== 'parRefOffset' && key !== 'APN' && key !== 'sleepCycle' && value}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
