import { I18n } from 'aws-amplify'
import print from 'print-js'
import { PrinterIcon } from '@heroicons/react/24/solid'

import QRCode from './QRCode'

import { getDevice } from '../../reducers/selectors'

const StepQR = ({ onHasPrinted }) => {
  const device = getDevice()

  function onClickPrint() {
    onHasPrinted()
    print({
      printable: 'qr-code',
      type: 'html',
      targetStyles: ['*']
    })
  }

  return (
    <div className='flex flex-col flex-start gap-2 mt-2 items-start'>
      <button
        onClick={onClickPrint}
        className={
          'px-4 py-2 w-24 bg-teal-400 text-black rounded hover:bg-teal-500 font-bold text-sm flex gap-2'
        }
      >
        {I18n.get('Print')}
        <PrinterIcon className='h5 w-5 self-center' />
      </button>
      <QRCode device={device} />
    </div>
  )
}

export default StepQR
