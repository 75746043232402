import { useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Hub, I18n } from 'aws-amplify'
import { ArrowLongLeftIcon } from '@heroicons/react/24/solid'

import FormHeader from './FormHeader'
import FormWrapper from './FormWrapper'
import Loader from '../Shared/Loader'
import Banner from '../Shared/Banner'

import { setSuccess } from '../../actions/util'
import { useAuth } from '../../contexts/auth-context'
import useAuthChannel from '../../hooks/useAuthChannel'
import { getFormErrors } from '../../utils/form'
import { getBannerSuccessMsg } from '../../reducers/selectors'

import { signInSchema, sendResetLinkSchema } from './validations'

const initalState = {
  password: '',
  username: '',
  loading: false,
  forgotPassword: false
}

const reducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.id]: action.value }
  }
  return state
}

const inputDefaultStyle =
  'p-4 rounded w-full bg-white h-12 text-black font-light mt-2 bg-slate-100/10 focus:outline focus:outline-teal-100 focus:ring-1 focus:ring-teal-400 focus:ring-opacity-50 focus:border-teal-400'

function SignIn({ hubData }) {
  const reduxDispatch = useDispatch()
  const auth = useAuth()
  const [authChannel, channelId] = useAuthChannel()
  const [state, dispatch] = useReducer(reducer, initalState)
  const [errors, setErrors] = useState([])
  const successMsg = getBannerSuccessMsg()

  useEffect(() => {
    return () => Hub.remove(channelId)
  }, [channelId])

  useEffect(() => {
    if (hubData.type === 'UserForgotPasswordSuccess') {
      reduxDispatch(
        setSuccess(
          I18n.get(
            'Password successfully changed. Sign in using your new password.'
          )
        )
      )
    } else if (hubData.code === 'LimitExceededException') {
      setErrors([I18n.get('Too many tries. Try again later.')])
    }
  }, [hubData, reduxDispatch])

  useEffect(() => {
    switch (authChannel.message) {
      case 'error':
        if (authChannel.data.code === 'NotAuthorizedException') {
          setErrors([I18n.get('Incorrect username or password')])
        } else if (authChannel.data.code === 'LimitExceededException') {
          setErrors([I18n.get('Too many tries. Try again later.')])
        } else if (authChannel.data.code === 'UserDisabled') {
          setErrors([
            I18n.get(
              'Your user has been disabled. Please contact your administrator.'
            )
          ])
        }
        setLoading(false)
        break
      default:
        break
    }
  }, [authChannel, channelId])

  const onSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setLoading(true)
    if (state.forgotPassword) {
      await onForgotPassword()
    } else {
      await onSignIn()
    }
    setLoading(false)
  }

  const onForgotPassword = async () => {
    try {
      await sendResetLinkSchema.validate(state, { abortEarly: false })
      await auth.forgotPassword(state.username)
    } catch (error) {
      setErrors(getFormErrors(error))
    }
  }

  const onSignIn = async () => {
    try {
      await signInSchema.validate(state, { abortEarly: false })
      await auth.signIn(state.username, state.password)
    } catch (error) {
      setErrors(getFormErrors(error))
    }
  }

  const clearErrors = (errors = {}) => {
    setErrors([])
  }

  const setLoading = loading => {
    dispatch({ type: 'update', id: 'loading', value: loading })
  }

  const onChangeInput = e => {
    const { id, value } = e.currentTarget
    dispatch({ type: 'update', id, value })
  }

  const goToForgotPassword = async e => {
    e.preventDefault()
    dispatch({ type: 'update', id: 'forgotPassword', value: true })
  }

  const goToSignIn = async e => {
    e.preventDefault()
    dispatch({ type: 'update', id: 'forgotPassword', value: false })
  }

  return (
    <FormWrapper onSubmit={onSubmit}>
      <FormHeader
        headerTitle={
          !state.forgotPassword
            ? I18n.get('Welcome back')
            : I18n.get('Reset your password')
        }
        headerSubtitle={I18n.get('Enter your details to proceed')}
      />
      {successMsg && <Banner inline={true} />}
      {Object.keys(errors)?.length > 0 && (
        <Banner isError errorMessages={errors} hideBanner={clearErrors} />
      )}
      <div className='mb-4'>
        <label className='text-base font-extrabold'>
          {I18n.get('Email address')}
        </label>
        <input
          type='text'
          id='username'
          value={state.username}
          onChange={onChangeInput}
          className={inputDefaultStyle}
          placeholder={I18n.get('Enter your email addresss')}
        />
      </div>
      {!state.forgotPassword && (
        <div className='mb-10'>
          <label className='text-base font-extrabold'>
            {I18n.get('Password')}
          </label>
          <input
            type='password'
            id='password'
            value={state.password}
            onChange={onChangeInput}
            className={inputDefaultStyle}
            placeholder={I18n.get('Enter your password')}
          />
        </div>
      )}
      <button
        type='submit'
        className='p-2 mt-4 bg-teal-400 text-black rounded w-full mx-auto hover:bg-teal-300 font-bold text-base'
        disabled={state.loading}
      >
        {!state.forgotPassword
          ? I18n.get('Sign in')
          : I18n.get('Send reset link')}
      </button>
      {!state.forgotPassword && (
        <div className='text-center mt-4'>
          <button
            onClick={goToForgotPassword}
            className='text-sm hover:underline'
          >
            {I18n.get('Forgot password? Click here.')}
          </button>
        </div>
      )}
      {state.forgotPassword && (
        <div className='text-center mt-4'>
          <button onClick={goToSignIn} className='text-sm hover:underline'>
            <div className='flex items-center justify-center gap-4'>
              <ArrowLongLeftIcon className='h-5 w-5 mx-auto' />
              <p>{I18n.get('Back to sign in')}</p>
            </div>
          </button>
        </div>
      )}
      {state.loading && (
        <Loader
          text={
            !state.forgotPassword
              ? I18n.get('Signing in')
              : I18n.get('Sending email')
          }
        />
      )}
    </FormWrapper>
  )
}

export default SignIn
