import { useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import { getUser } from '../../../reducers/selectors'

import { useAuth } from '../../../contexts/auth-context'

import IconSvg from '../../../assets/icon.svg'

const navigation = [
  { name: 'EspTool', href: '/esptool' },
  { name: 'Users', href: '/users' }
]

export default function Header() {
  const auth = useAuth()
  const navigate = useNavigate()

  const currentUser = getUser()

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  async function onSignOut() {
    await auth.signOut()
    navigate('/')
  }

  return (
    <header className='bg-white'>
      <nav
        className='mx-auto flex max-w-4xl items-center justify-between py-4 px-4 lg:px-8'
        aria-label='Global'
      >
        <div className='flex items-center gap-x-6'>
          <Link to='/' className='-m-1.5 p-1.5'>
            <span className='sr-only'>Coretex</span>
            <img className='h-12 w-auto' src={IconSvg} alt='Coretex' />
          </Link>
          <div className='hidden lg:flex lg:gap-x-6'>
            {currentUser.type === 'admin' &&
              navigation.map(item => (
                <Link
                  key={item.name}
                  to={item.href}
                  className='text-sm font-semibold leading-6 text-gray-900'
                >
                  {item.name}
                </Link>
              ))}
          </div>
        </div>
        <div className='flex lg:hidden'>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className='sr-only'>Open main menu</span>
            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div className='hidden lg:flex'>
          <button
            className='text-sm font-semibold leading-6 text-gray-900 hover:text-teal-700'
            onClick={onSignOut}
          >
            Sign out <span aria-hidden='true'>&rarr;</span>
          </button>
        </div>
      </nav>
      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-10' />
        <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <Link to='/' className='-m-1.5 p-1.5'>
              <span className='sr-only'>Coretex</span>
              <img className='h-8 w-auto' src={IconSvg} alt='Coretex' />
            </Link>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XMarkIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-1 py-6'>
                {currentUser.type === 'admin' &&
                  navigation.map(item => (
                    <Link
                      key={item.name}
                      to={item.href}
                      onClick={() => setMobileMenuOpen(false)}
                      className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                    >
                      {item.name}
                    </Link>
                  ))}
              </div>
              <div className='py-6'>
                <button
                  className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                  onClick={onSignOut}
                >
                  Sign out
                </button>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
