/**
 * Returns action creator made from action.type & applies all of the arguments passed
 * @param {string} type
 * @param  {...any} argNames
 */
export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = { type }
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    })

    return action
  }
}
/**
 * Returns reducer created from handlers & initialState
 *
* @link https://medium.com/better-programming/write-cleaner-reducers-1c433a94a166

 * @param {Object} handlers
 * @param {Object} initialState
 */
export function createReducer(handlers, initialState = {}) {
  // eslint-disable-next-line
  return function (state = initialState, action) {
    const handler = handlers[action.type]
    if (!handler) {
      return state
    }

    const nextState = handler(state, action)
    return nextState
  }
}
