import { I18n } from 'aws-amplify'

import QRCode from './QRCode'
import { getDevice } from '../../reducers/selectors'

const StepSummary = () => {
  const device = getDevice()

  return (
    <div className='flex flex-col gap-4 mt-2 items-start'>
      <h3 className='font-semibold text-lg text-teal-700'>
        {I18n.get('Device provisioned successfully.')}
      </h3>
      <p className='text-base'>
        {I18n.get('Device ID')}:{' '}
        <span className='font-medium'>{device?.deviceId ?? 'fake-id'}</span>
      </p>
      <p className='text-base'>
        {I18n.get('Device type')}:{' '}
        <span className='font-medium'>{device?.type ?? 'fake-type'}</span>
      </p>
      <QRCode device={device} />
    </div>
  )
}

export default StepSummary
