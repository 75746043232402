export const getFormErrors = err => {
  if (err?.inner && err?.inner?.length > 0) {
    return err.inner.map(innerErr => {
      return innerErr.message
    })
  }

  if (err?.message) return [err.message]

  return []
}
