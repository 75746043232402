import { createElement, Fragment, useEffect, useState } from 'react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'

import Router from './Router'

const Wrapper = () => {
  const [authState, setAuthState] = useState('signin')

  useEffect(() => {
    return onAuthUIStateChange(nextAuthState => {
      setAuthState(nextAuthState)
    })
  }, [])

  if (authState === AuthState.SignedIn) {
    return null
  }

  return (
    <Fragment>
      <div className='flex h-screen'>
        <div className='flex h-screen w-full flex-col px-6 pt-6 lg:px-8'>
          {createElement(Router)}
        </div>
      </div>
    </Fragment>
  )
}

export default Wrapper
