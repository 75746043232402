import { call, put, takeEvery } from 'redux-saga/effects'
import Papa from 'papaparse'

import {
  deviceTypesFetched,
  nvsPartitionTemplateFetched
} from '../../actions/device'
// import { supplierFetched } from '../../actions/supplier'
import { userFetched, usersFetched } from '../../actions/user'
import { FETCH_INIT, initFetched } from '../../actions/init'
import { setErrors } from '../../actions/util'

import { getDeviceTypes, getNvsPartitionCsv } from '../../api/device'
// import { getCurrentSupplier } from '../../api/supplier'
import { getCurrentUser, getUsers } from '../../api/user'

function* sendFetchInit(action) {
  const deviceTypesResponse = yield call(getDeviceTypes)

  yield put(
    deviceTypesFetched(
      deviceTypesResponse.deviceTypes,
      deviceTypesResponse.error
    )
  )

  const nvsPartitionResponse = yield call(getNvsPartitionCsv, false)
  let templateData = []

  if (!nvsPartitionResponse.error) {
    const result = Papa.parse(nvsPartitionResponse.nvsPartition.template, {
      skipEmptyLines: true
    })
    templateData = result.data
  }

  yield put(
    nvsPartitionTemplateFetched(templateData, nvsPartitionResponse.error)
  )

  // const supplierResponse = yield call(getCurrentSupplier)
  // yield put(supplierFetched(supplierResponse.supplier, supplierResponse.error))

  const userResponse = yield call(getCurrentUser)
  yield put(userFetched(userResponse.user, userResponse.error))

  let isSuccess = !deviceTypesResponse.error && !userResponse.error

  if (userResponse?.user?.type === 'admin') {
    const usersResponse = yield call(getUsers, userResponse.user.supplierId)
    yield put(usersFetched(usersResponse.users, usersResponse.error))
    isSuccess =
      !deviceTypesResponse.error && !userResponse.error && !usersResponse.error
  }

  yield put(initFetched(isSuccess))

  if (!isSuccess) {
    yield put(
      setErrors([
        'Something went wrong loading the initial data. Please refresh the page.'
      ])
    )
  }
}

export function* watchSendFetchInit() {
  yield takeEvery(FETCH_INIT, sendFetchInit)
}
