import { useEffect, useState, Fragment } from 'react'
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components'

import AuthWrapper from './Wrapper'

import { useAuth } from '../../contexts/auth-context'

const withAuthenticator = Component => {
  const AppWithAuthenticator = props => {
    const auth = useAuth()
    const [signedIn, setSignedIn] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      return checkUser()
    }, [])

    function checkUser() {
      setUser()

      return onAuthUIStateChange(authState => {
        if (authState === AuthState.SignedIn) {
          setSignedIn(true)
        } else if (authState === AuthState.SignedOut) {
          setSignedIn(false)
        }
      })
    }

    async function setUser() {
      const isLoggedIn = await auth.isAuthenticated()
      setIsLoading(false)
      if (isLoggedIn) {
        setSignedIn(true)
      }
    }

    return (
      <Fragment>
        {!signedIn && !isLoading && <AuthWrapper />}
        {signedIn && !isLoading && <Component {...props} />}
      </Fragment>
    )
  }

  return AppWithAuthenticator
}

export default withAuthenticator
