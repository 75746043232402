import { makeActionCreator } from '../../utils/redux'

export const CREATE_DEVICE = 'CREATE_DEVICE'
export const createDevice = makeActionCreator(CREATE_DEVICE, 'params')

export const DEVICE_CREATED = 'DEVICE_CREATED'
export const deviceCreated = makeActionCreator(
  DEVICE_CREATED,
  'device',
  'error'
)

export const DEVICE_TYPES_FETCHED = 'DEVICE_TYPES_FETCHED'
export const deviceTypesFetched = makeActionCreator(
  DEVICE_TYPES_FETCHED,
  'deviceTypes',
  'error'
)

export const NVS_PARTITION_TEMPLATE_FETCHED = 'NVS_PARTITION_TEMPLATE_FETCHED'
export const nvsPartitionTemplateFetched = makeActionCreator(
  NVS_PARTITION_TEMPLATE_FETCHED,
  'data',
  'error'
)

export const CLEAN_DEVICE = 'CLEAN_DEVICE'
export const cleanDevice = makeActionCreator(CLEAN_DEVICE)

export const SELECT_DEVICE_TYPE = 'SELECT_DEVICE_TYPE'
export const selectDeviceType = makeActionCreator(
  SELECT_DEVICE_TYPE,
  'deviceType'
)

export const SELECT_DEVICE_TYPE_HARDWARE = 'SELECT_DEVICE_TYPE_HARDWARE'
export const selectDeviceTypeHardware = makeActionCreator(
  SELECT_DEVICE_TYPE_HARDWARE,
  'hardware'
)

export const UPDATE_NVS_PARTITION = 'UPDATE_NVS_PARTITION'
export const updateNvsPartition = makeActionCreator(
  UPDATE_NVS_PARTITION,
  'data'
)

export const NEXT_STEP = 'NEXT_STEP'
export const nextStep = makeActionCreator(NEXT_STEP)

export const PREVIOUS_STEP = 'PREVIOUS_STEP'
export const previousStep = makeActionCreator(PREVIOUS_STEP)

export const RESTART_STEPS = 'RESTART_STEPS'
export const restartSteps = makeActionCreator(RESTART_STEPS)
