import { combineReducers } from 'redux'

import { deviceReducer } from './device'
import { supplierReducer } from './supplier'
import { userReducer } from './user'
import { utilReducer } from './util'

export default combineReducers({
  deviceReducer,
  supplierReducer,
  userReducer,
  utilReducer
})
