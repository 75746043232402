import { Fragment } from 'react'

const StepTitle = ({ title }) => {
  return (
    <Fragment>
      <div className='py-2'>
        <h2 className='font-extrabold text-xl'>{title}</h2>
      </div>
      <div className='pb-2'>
        <hr />
      </div>
    </Fragment>
  )
}

export default StepTitle
