import { Fragment } from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'

import { STEPS } from '../../reducers/device'
import { getPreviousSteps, getCurrentStep } from '../../reducers/selectors'

const Steps = () => {
  const previousSteps = getPreviousSteps()
  const currentStep = getCurrentStep()

  return (
    <nav aria-label='Progress' className='pb-2'>
      <ol className='divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0'>
        {STEPS.map((step, stepIdx) => (
          <li key={step.id} className='relative md:flex md:flex-1'>
            {previousSteps.find(({ id }) => id === step.id) ? (
              <div className='group flex w-full items-center'>
                <span className='flex items-center px-6 py-4 text-sm font-medium'>
                  <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-teal-600'>
                    <CheckIcon
                      className='h-6 w-6 text-white'
                      aria-hidden='true'
                    />
                  </span>
                  <span className='ml-4 text-sm font-medium text-gray-900'>
                    {step.name}
                  </span>
                </span>
              </div>
            ) : currentStep.id === step.id ? (
              <div
                className='flex items-center px-6 py-4 text-sm font-medium'
                aria-current='step'
              >
                <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-teal-600'>
                  <span className='text-teal-600'>{stepIdx + 1}</span>
                </span>
                <span className='ml-4 text-sm font-medium text-teal-600'>
                  {step.name}
                </span>
              </div>
            ) : (
              <div className='group flex items-center'>
                <span className='flex items-center px-6 py-4 text-sm font-medium'>
                  <span className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-400'>
                    <span className='text-gray-500'>{stepIdx + 1}</span>
                  </span>
                  <span className='ml-4 text-sm font-medium text-gray-500'>
                    {step.name}
                  </span>
                </span>
              </div>
            )}

            {stepIdx !== STEPS.length - 1 ? (
              <Fragment>
                {/* Arrow separator for lg screens and up */}
                <div
                  className='absolute right-0 top-0 hidden h-full w-5 md:block'
                  aria-hidden='true'
                >
                  <svg
                    className='h-full w-full text-gray-300'
                    viewBox='0 0 22 80'
                    fill='none'
                    preserveAspectRatio='none'
                  >
                    <path
                      d='M0 -2L20 40L0 82'
                      vectorEffect='non-scaling-stroke'
                      stroke='currentcolor'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </Fragment>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Steps
