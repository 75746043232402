import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { I18n } from 'aws-amplify'

import { classNames } from '../../../utils/classes'

const OPTIONS = [
  {
    id: '921600',
    name: '921600'
  },
  {
    id: '460800',
    name: '460800'
  },
  {
    id: '230400',
    name: '230400'
  },
  {
    id: '115200',
    name: '115200'
  }
]

const BaudratesSelect = ({ disabled, onSelectDeviceBaudrate }) => {
  const [selected, setSelected] = useState(OPTIONS[0])

  function onSelectBaudrate(value) {
    setSelected(value)
    onSelectDeviceBaudrate(value)
  }

  return (
    <div className='mt-2'>
      <p className='font-semibold text-sm'>{I18n.get('Select baudrates')}</p>
      <Listbox value={selected} onChange={onSelectBaudrate} disabled={disabled}>
        {({ open }) => (
          <Fragment>
            <div className='relative mt-2 w-full'>
              <Listbox.Button className='relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-600 sm:text-sm sm:leading-6'>
                <span className='block truncate'>{selected?.name}</span>
                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                  <ChevronUpDownIcon
                    className='h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm text-left'>
                  {OPTIONS.map(option => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-teal-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-8 pr-4'
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <Fragment>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {option.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-teal-600',
                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                              )}
                            >
                              <CheckIcon
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            </span>
                          ) : null}
                        </Fragment>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Fragment>
        )}
      </Listbox>
    </div>
  )
}

export default BaudratesSelect
