import LogoSvg from '../../assets/logo.svg'

function FormHeader({ headerTitle, headerSubtitle }) {
  return (
    <div className='mb-8 flex flex-col items-center justify-center text-center text-black'>
      <div className='pb-4'>
        <img className='h-28 w-auto' src={LogoSvg} alt='Coretex' />
      </div>
      <h2 className='mb-2 text-2xl font-extrabold md:text-3xl'>
        {headerTitle}
      </h2>
      <p className='text-base text-gray-600 md:text-lg '>{headerSubtitle}</p>
    </div>
  )
}

export default FormHeader
