import { I18n } from 'aws-amplify'
import { useReducer, useState } from 'react'

import FormHeader from './FormHeader'
import FormWrapper from './FormWrapper'
import Loader from '../Shared/Loader'
import Banner from '../Shared/Banner'

import {
  changePasswordSchema,
  forgotPasswordSchema,
  passwordSchema
} from './validations'
import { useAuth } from '../../contexts/auth-context'
import { getFormErrors } from '../../utils/form'

const labels = {
  code: I18n.get('Code'),
  oldPassword: I18n.get('Old password'),
  newPassword: I18n.get('New password'),
  confirmPassword: I18n.get('Confirm new password')
}

const placeholders = {
  code: I18n.get('Enter the code sent to your email'),
  oldPassword: I18n.get('Enter your old password'),
  newPassword: I18n.get('Enter your new password'),
  confirmPassword: I18n.get('Confirm your new password')
}

const initalState = {
  code: '',
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
}

const reducer = (state, action) => {
  if (action.type === 'update') {
    return { ...state, [action.id]: action.value }
  }
  return state
}

const inputDefaultStyle =
  'p-4 rounded w-full bg-white h-12 text-black font-light mt-2 bg-slate-100/10 focus:outline focus:outline-teal-100 focus:ring-1 focus:ring-teal-400 focus:ring-opacity-50 focus:border-teal-400'

function PasswordReset({
  type = 'changePassword',
  hubData = {},
  needOldPassword = false,
  needCode = false
}) {
  const [loading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [state, dispatch] = useReducer(reducer, initalState)
  const auth = useAuth()

  const onChangeInput = e => {
    const { id, value } = e.currentTarget
    dispatch({ type: 'update', id, value })
  }

  const clearErrors = () => {
    setErrors([])
  }

  const onSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setIsLoading(true)
    if (type === 'UserForgotPassword') {
      await onForgotPassword()
    } else if (type === 'changePassword') {
      await onChangePassword()
    } else if (type === 'NewPasswordRequired') {
      await onCompleteNewPassword()
    }
    setIsLoading(false)
  }

  const onChangePassword = async () => {
    try {
      await changePasswordSchema.validate(state, { abortEarly: false })
      await auth.changePassword(
        state.oldPassword,
        state.newPassword,
        hubData.user
      )
    } catch (err) {
      setErrors(getFormErrors(err))
    }
  }

  const onForgotPassword = async () => {
    try {
      await forgotPasswordSchema.validate(state, { abortEarly: false })
      await auth.forgotPasswordSubmit(
        hubData.username,
        state.code,
        state.newPassword
      )
    } catch (error) {
      setErrors(getFormErrors(error))
    }
  }

  const onCompleteNewPassword = async () => {
    try {
      await passwordSchema.validate(state, { abortEarly: false })
      await auth.completeNewPassword(state.newPassword)
    } catch (error) {
      setErrors(getFormErrors(error))
    }
  }

  const getShowField = key => {
    if (
      (key === 'code' && !needCode) ||
      (key === 'oldPassword' && !needOldPassword)
    ) {
      return false
    }

    return true
  }

  const keys = Object.keys(state)

  return (
    <FormWrapper onSubmit={onSubmit}>
      <FormHeader
        headerTitle={I18n.get('Reset your password')}
        headerSubtitle={I18n.get('Enter your new password')}
      />
      {Object.keys(errors)?.length > 0 && (
        <Banner isError errorMessages={errors} hideBanner={clearErrors} />
      )}
      {keys.map((key, index) => {
        return (
          getShowField(key) && (
            <div
              className={index === keys.length - 1 ? 'mb-10' : 'mb-4'}
              key={key}
            >
              <label className='text-base font-extrabold'>{labels[key]}</label>
              <input
                type={key === 'code' ? 'text' : 'password'}
                id={key}
                value={state[key]}
                onChange={onChangeInput}
                className={inputDefaultStyle}
                placeholder={placeholders[key]}
              />
            </div>
          )
        )
      })}
      <button
        type='submit'
        className='p-2 mt-4 bg-teal-400 text-black rounded w-full mx-auto hover:bg-teal-300 font-bold text-base'
      >
        {I18n.get('Save password')}
      </button>
      {loading && <Loader text={I18n.get('Updating password')} />}
    </FormWrapper>
  )
}

export default PasswordReset
