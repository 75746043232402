import { Fragment, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'

import withAuthenticator from '../components/Auth/withAuthenticator'
import Banner from '../components/Shared/Banner'
import Header from '../components/Shared/Header'
import Loader from '../components/Shared/Loader'

import { fetchInit } from '../actions/init'
import { setErrors, webSerialNotSupported } from '../actions/util'

import { getUtilLoading } from '../reducers/selectors'

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const loading = getUtilLoading()

  useEffect(() => {
    dispatch(fetchInit())
    getSerialPorts()
  }, [dispatch])

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/esptool')
    }
  }, [navigate, location])

  async function getSerialPorts() {
    if ('serial' in navigator) {
      await navigator.serial.getPorts()
    } else {
      dispatch(setErrors(['Web Serial API not supported in this browser.']))
      dispatch(webSerialNotSupported(true))
    }
  }

  return (
    <Fragment>
      <Header />
      <Banner />
      <div className='h-screen mx-auto max-w-4xl flex flex-col px-4 lg:px-8'>
        <Outlet />
      </div>
      {loading && <Loader />}
    </Fragment>
  )
}

export default withAuthenticator(App)
