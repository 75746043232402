import { I18n } from 'aws-amplify'

const TableContents = ({ memoryAddresses, binFiles }) => {
  return (
    <div className='mx-w-2xl'>
      <table className='min-w-full divide-y divide-gray-300'>
        <thead>
          <tr>
            <th
              scope='col'
              className='px-2 py-2.5 text-left text-sm font-semibold text-gray-900'
            >
              {I18n.get('Partition')}
            </th>
            <th
              scope='col'
              className='px-2 py-2.5 text-left text-sm font-semibold text-gray-900'
            >
              {I18n.get('Memory address')}
            </th>
          </tr>
        </thead>
        <tbody>
          {memoryAddresses.map((address, index) => {
            return (
              <tr key={`${index}-${address}`} className='even:bg-gray-50'>
                <td className='whitespace-nowrap px-2 py-3 text-sm text-gray-500'>
                  {binFiles[index]}
                </td>
                <td className='whitespace-nowrap px-2 py-3 text-sm text-gray-500'>
                  {`0x${address.toString(16)}`}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TableContents
