import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

export async function getNvsPartitionCsv(isGreengrass = false) {
  try {
    const query = /* GraphQL */ `
      query getNvsPartitionCsv($isGreengrass: Boolean) {
        getNvsPartitionCsv(isGreengrass: $isGreengrass) {
          template
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, { isGreengrass })
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return {
      nvsPartition: response.data.getNvsPartitionCsv,
      error: null
    }
  } catch (err) {
    return { nvsPartition: null, error: err.message }
  }
}

export async function getDeviceTypes() {
  try {
    const query = /* GraphQL */ `
      query getDeviceTypes {
        getDeviceTypes {
          id
          name
          type
          measurements
          hasFirmware
          hardware {
            active
            hardwareVersion
            flashScriptVersion
            firmwareVersion {
              date
              version
            }
          }
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, {})
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return { deviceTypes: response.data.getDeviceTypes, error: null }
  } catch (err) {
    return { deviceTypes: null, error: err.message }
  }
}

export async function createDevice(params) {
  try {
    const mutation = /* GraphQL */ `
      mutation createDevice(
        $type: String!
        $hardwareVersion: String!
        $firmwareVersion: String
        $flashScriptVersion: String
        $nvsPartition: [NvsPartitionInput]
      ) {
        createDevice(
          type: $type
          hardwareVersion: $hardwareVersion
          firmwareVersion: $firmwareVersion
          flashScriptVersion: $flashScriptVersion
          nvsPartition: $nvsPartition
        ) {
          deviceId
          name
          type
          firmwareVersion
          flashScriptVersion
          zipUrl
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(mutation, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return { device: response.data.createDevice, error: null }
  } catch (err) {
    return { device: null, error: err.message }
  }
}
