import { call, put, takeEvery } from 'redux-saga/effects'

import {
  CREATE_DEVICE,
  deviceCreated,
  restartSteps
} from '../../actions/device'

import { setErrors } from '../../actions/util'

import { createDevice } from '../../api/device'

function* sendCreateDevice(action) {
  const { device, error } = yield call(createDevice, action.params)

  yield put(deviceCreated(device, error))

  if (error) {
    yield put(restartSteps())
    yield put(
      setErrors([
        'Something went wrong creating the device files. Please try again.'
      ])
    )
  }
}

export function* watchCreateDevice() {
  yield takeEvery(CREATE_DEVICE, sendCreateDevice)
}
