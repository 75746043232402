import { I18n } from 'aws-amplify'

import { createReducer } from '../../utils/redux'

import {
  DEVICE_TYPES_FETCHED,
  CREATE_DEVICE,
  DEVICE_CREATED,
  CLEAN_DEVICE,
  SELECT_DEVICE_TYPE,
  SELECT_DEVICE_TYPE_HARDWARE,
  UPDATE_NVS_PARTITION,
  NEXT_STEP,
  PREVIOUS_STEP,
  RESTART_STEPS,
  NVS_PARTITION_TEMPLATE_FETCHED
} from '../../actions/device'

export const STEPS = [
  { id: 'create-files', name: I18n.get('Generate device files') },
  { id: 'flash-device', name: I18n.get('Flash device') },
  { id: 'print-code', name: I18n.get('Print QR code') }
]

const initialState = {
  deviceTypes: [],
  nvsPartitionTemplate: [],
  selectedDeviceType: {},
  selectedHardare: {},
  nvsPartition: [],
  previousSteps: [],
  currentStep: STEPS[0],
  device: {},
  loading: false
}
const handler = {
  [NVS_PARTITION_TEMPLATE_FETCHED]: (state, action) => ({
    ...state,
    nvsPartitionTemplate: !action.error
      ? action.data
      : state.nvsPartitionTemplate
  }),
  [UPDATE_NVS_PARTITION]: (state, action) => ({
    ...state,
    nvsPartition: action.data
  }),
  [DEVICE_TYPES_FETCHED]: (state, action) => ({
    ...state,
    deviceTypes: !action.error ? action.deviceTypes : state.deviceTypes
  }),
  [CREATE_DEVICE]: (state, action) => ({
    ...state,
    loading: true
  }),
  [DEVICE_CREATED]: (state, action) => ({
    ...state,
    device: !action.error ? action.device : state.device,
    loading: false
  }),
  [CLEAN_DEVICE]: state => ({
    ...state,
    device: {},
    selectedDeviceType: {},
    selectedHardare: {},
    previousSteps: [],
    currentStep: STEPS[0]
  }),
  [SELECT_DEVICE_TYPE]: (state, action) => ({
    ...state,
    selectedDeviceType: action.deviceType
  }),
  [SELECT_DEVICE_TYPE_HARDWARE]: (state, action) => ({
    ...state,
    selectedHardare: action.hardware
  }),
  [NEXT_STEP]: state => ({
    ...state,
    previousSteps: [...state.previousSteps, state.currentStep],
    currentStep: STEPS[state.previousSteps.length + 1]
      ? STEPS[state.previousSteps.length + 1]
      : { id: 'summary', name: I18n.get('Summary') }
  }),
  [PREVIOUS_STEP]: state => ({
    ...state,
    previousSteps: state.previousSteps.slice(0, -1),
    currentStep: state.previousSteps[state.previousSteps.length - 1]
  }),
  [RESTART_STEPS]: state => ({
    ...state,
    previousSteps: [],
    currentStep: STEPS[0]
  })
}

export const deviceReducer = createReducer(handler, initialState)
