import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { XMarkIcon } from '@heroicons/react/24/solid'

import { clearErrors, clearSuccess } from '../../../actions/util'

import {
  getBannerErrors,
  getBannerSuccessMsg
} from '../../../reducers/selectors'

const errorBanner = 'bg-orange-400'
const successBanner = 'bg-green-500'

const Banner = ({ errorMessages = [], hideBanner = null, inline = false }) => {
  //TODO: refactor auth pages to update store instead of passing params
  const dispatch = useDispatch()
  const errors = getBannerErrors()
  const successMsg = getBannerSuccessMsg()
  const allErrors = [...errors, ...errorMessages]
  const hasErrors = allErrors.length > 0
  const hasSuccessMsg = successMsg?.length > 0
  const hasInjectedErrors = errorMessages.length > 0

  useEffect(() => {
    if (hasSuccessMsg) {
      setTimeout(() => {
        dispatch(clearSuccess())
      }, '10000')
    }
  }, [dispatch, hasSuccessMsg])

  if (!hasErrors && !hasSuccessMsg) return null

  const onClickClose = () => {
    if (hasErrors) dispatch(clearErrors())
    if (hasSuccessMsg) dispatch(clearSuccess())
  }

  return (
    <div
      className={`w-full p-4 flex justify-between items-start ${
        hasErrors ? errorBanner : successBanner
      } ${
        hasInjectedErrors || inline ? 'relative mb-8' : 'absolute top-0 z-30'
      }`}
    >
      <div>
        {hasErrors &&
          Object.keys(allErrors).map(key => (
            <div className='w-full block' key={key}>
              <p className='text-base font-bold'>{allErrors[key]}</p>
            </div>
          ))}
        {hasSuccessMsg && (
          <div className='w-full block'>
            <p className='text-base font-bold'>{successMsg}</p>
          </div>
        )}
      </div>
      <button onClick={hideBanner || onClickClose}>
        <XMarkIcon className='h-6 w-6' />
      </button>
    </div>
  )
}

export default Banner
