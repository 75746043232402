import { all, fork } from 'redux-saga/effects'

/** Device **/
import { watchCreateDevice } from './device'

/** Init **/
import { watchSendFetchInit } from './init'

/** User **/
import { watchCreateUser, watchDeleteUser } from './user'

export default function* rootSaga() {
  yield all([fork(watchCreateDevice)])
  yield all([fork(watchSendFetchInit)])
  yield all([fork(watchCreateUser), fork(watchDeleteUser)])
}
