import { Auth, Hub } from 'aws-amplify'
import {
  AuthState,
  AUTH_STATE_CHANGE_EVENT,
  UI_AUTH_CHANNEL
} from '@aws-amplify/ui-components'
import { isEmpty } from '@aws-amplify/core'

function dispatchAuthChannel(message, data = {}) {
  Hub.dispatch(UI_AUTH_CHANNEL, {
    event: AUTH_STATE_CHANGE_EVENT,
    message,
    data
  })
}

export class AuthService {
  cognitoUser = null

  async currentUser() {
    try {
      this.cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true
      })
    } catch (error) {
      this.cognitoUser = null
    }
  }

  async isAuthenticated() {
    try {
      this.cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true
      })
      return true
    } catch (error) {
      this.cognitoUser = null
      return false
    }
  }

  async verifyContact() {
    try {
      const data = await Auth.verifiedContact(this.cognitoUser)
      if (data.unverified.email) {
        dispatchAuthChannel(AuthState.VerifyingAttributes, data)
        this.verifyUserAttribute('email')
      } else if (!isEmpty(data.verified)) {
        dispatchAuthChannel(AuthState.SignedIn, data)
      } else {
        await this.signOut()
      }
    } catch (error) {
      throw error
    }
  }

  async signIn(username, password) {
    try {
      this.cognitoUser = await Auth.signIn(username, password)

      if (this.cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const data = { type: 'NewPasswordRequired' }
        dispatchAuthChannel(AuthState.ResetPassword, data)
      } else {
        await this.verifyContact()
      }
    } catch (error) {
      if (error.code === 'PasswordResetRequiredException') {
        this.forgotPassword(username, 'PasswordResetRequiredException')
      }
      if (error.code === 'NotAuthorizedException') {
        const isDisabled = error.message
          .toLocaleLowerCase()
          .includes('disabled')

        dispatchAuthChannel('error', {
          code: isDisabled ? 'UserDisabled' : 'NotAuthorizedException'
        })
      }
      throw error
    }
  }

  async signOut() {
    await Auth.signOut()
    this.cognitoUser = null
    dispatchAuthChannel(AuthState.SignedOut)
  }

  async completeNewPassword(newPassword) {
    try {
      await Auth.completeNewPassword(this.cognitoUser, newPassword)
      const data = await Auth.verifiedContact(this.cognitoUser)
      dispatchAuthChannel(AuthState.SignedIn, data)
    } catch (error) {
      throw error
    }
  }

  async forgotPassword(username, type = 'UserForgotPassword') {
    try {
      const { CodeDeliveryDetails } = await Auth.forgotPassword(username)
      dispatchAuthChannel(AuthState.ResetPassword, {
        type,
        deliveryDetails: CodeDeliveryDetails,
        username
      })
    } catch (error) {
      if (error.code === 'LimitExceededException') {
        dispatchAuthChannel('error', { code: 'LimitExceededException' })
      }
      throw error
    }
  }

  async forgotPasswordSubmit(username, code, password) {
    try {
      await Auth.forgotPasswordSubmit(username, code, password)
      dispatchAuthChannel('success', {
        type: 'UserForgotPasswordSuccess'
      })
    } catch (error) {
      throw error
    }
  }

  async changePassword(oldPassword, newPassword) {
    try {
      await Auth.changePassword(this.cognitoUser, oldPassword, newPassword)
      dispatchAuthChannel('success', {
        type: 'UserChangePasswordSuccess'
      })
    } catch (error) {
      throw error
    }
  }

  async verifyUserAttribute(attr) {
    try {
      await Auth.verifyUserAttribute(this.cognitoUser, attr)
      dispatchAuthChannel(AuthState.VerifyingAttributes, { type: attr })
    } catch (error) {
      dispatchAuthChannel('error', {
        code: error.code
      })
      throw error
    }
  }

  async verifyCurrentUserAttributeSubmit(attr, code) {
    try {
      await Auth.verifyCurrentUserAttributeSubmit(attr, code)
      await this.verifyContact()
    } catch (error) {
      throw error
    }
  }
}
