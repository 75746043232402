import { I18n } from 'aws-amplify'

function Actions({ onAddUser }) {
  return (
    <div className='sm:flex sm:items-center'>
      <div className='sm:flex-auto'>
        <h2 className='font-extrabold text-xl'>{I18n.get('Users')}</h2>
        <p className='mt-2 text-sm text-gray-700'>
          {I18n.get(
            'A list of all the users in your account that can flash devices.'
          )}
        </p>
      </div>
      <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
        <button
          type='button'
          className='px-4 py-2 mt-4 bg-teal-400 text-black rounded hover:bg-teal-500 font-bold text-sm flex gap-2 justify-center'
          onClick={onAddUser}
        >
          {I18n.get('Add user')}
        </button>
      </div>
    </div>
  )
}

export default Actions
