import { makeActionCreator } from '../../utils/redux'

export const SET_ERRORS = 'SET_ERRORS'
export const setErrors = makeActionCreator(SET_ERRORS, 'errorMessages')

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const clearErrors = makeActionCreator(CLEAR_ERRORS)

export const SET_SUCCESS = 'SET_SUCCESS'
export const setSuccess = makeActionCreator(SET_SUCCESS, 'successMessage')

export const CLEAR_SUCCESS = 'CLEAR_SUCCESS'
export const clearSuccess = makeActionCreator(CLEAR_SUCCESS)

export const WEB_SERIAL_NOT_SUPPORTED = 'WEB_SERIAL_NOT_SUPPORTED'
export const webSerialNotSupported = makeActionCreator(
  WEB_SERIAL_NOT_SUPPORTED,
  'value'
)
