import { I18n } from 'aws-amplify'
import { useAuth } from '../../contexts/auth-context'
import { useState } from 'react'

import FormHeader from './FormHeader'
import FormWrapper from './FormWrapper'
import Loader from '../Shared/Loader'
import Banner from '../Shared/Banner'

import { getFormErrors } from '../../utils/form'
import { codeSchema } from './validations'

const inputDefaultStyle =
  'p-4 rounded w-full bg-white h-12 text-black font-light mt-2 bg-slate-100/10 focus:outline focus:outline-teal-100 focus:ring-1 focus:ring-teal-400 focus:ring-opacity-50 focus:border-teal-400'

function VerifyAttribute({ title, attribute, attributeValue }) {
  const [code, setCode] = useState('')
  const [loading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const auth = useAuth()
  const verifyAttribute = async e => {
    try {
      e.preventDefault()
      setErrors([])
      await codeSchema.validate({ code }, { abortEarly: false })
      setIsLoading(true)
      try {
        await auth.verifyCurrentUserAttributeSubmit(attribute, code)
      } finally {
        setIsLoading(false)
      }
    } catch (err) {
      setErrors(getFormErrors(err))
    }
  }

  const onChangeInput = e => {
    setCode(e.currentTarget.value)
  }

  const clearErrors = () => {
    setErrors([])
  }

  return (
    <FormWrapper onSubmit={verifyAttribute}>
      <FormHeader
        headerTitle={I18n.get('Verify your email addres')}
        headerSubtitle={`${I18n.get('A code was sent to')} ${
          auth?.cognitoUser?.attributes?.email ||
          attributeValue | I18n.get('your email')
        }`}
      />
      {Object.keys(errors)?.length > 0 && (
        <Banner isError errorMessages={errors} hideBanner={clearErrors} />
      )}
      <div className='mb-10'>
        <label className='text-base font-extrabold'>{I18n.get('Code')}</label>
        <input
          type='password'
          id='password'
          value={code}
          onChange={onChangeInput}
          className={inputDefaultStyle}
          placeholder={I18n.get('Enter code sent to your email address')}
        />
      </div>
      <button
        type='submit'
        className='p-2 mt-4 bg-teal-400 text-black rounded w-full mx-auto hover:bg-teal-300 font-bold text-base'
      >
        {`${I18n.get('Verify')} ${title}`}
      </button>
      {loading && <Loader text={I18n.get('Verifying')} />}
    </FormWrapper>
  )
}

export default VerifyAttribute
