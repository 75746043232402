import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'aws-amplify'
import {
  ArrowLongRightIcon,
  ArrowLongLeftIcon,
  ArrowPathIcon
} from '@heroicons/react/24/solid'

import Steps from '../components/EspTool/Steps'
import StepRouter from '../components/EspTool/StepRouter'
import StepTitle from '../components/EspTool/StepTitle'
import Loader from '../components/Shared/Loader'

import {
  createDevice,
  cleanDevice,
  nextStep,
  previousStep,
  restartSteps
} from '../actions/device'

import {
  getDeviceLoading,
  getCurrentStep,
  getSelectedDeviceType,
  getSelectedHardware,
  getNvsPartition,
  getWebSerialNotSupported,
  getInitSuccess
} from '../reducers/selectors'

function EspTool() {
  const reduxDispatch = useDispatch()

  const [flashFinished, setFlashFinished] = useState(false)
  const [hasPrinted, setHasPrinted] = useState(false)

  const loading = getDeviceLoading()
  const currentStep = getCurrentStep()
  const deviceType = getSelectedDeviceType()
  const hardware = getSelectedHardware()
  const nvsPartition = getNvsPartition()
  const webSerialNotSupported = getWebSerialNotSupported()
  const initSuccess = getInitSuccess()

  function onClickNextStep(e) {
    e.preventDefault()

    if (currentStep.id === 'create-files') {
      const params = {
        type: deviceType.id,
        hardwareVersion: hardware.hardwareVersion,
        firmwareVersion: hardware.firmwareVersion.date,
        flashScriptVersion: hardware.flashScriptVersion,
        nvsPartition: nvsPartition.slice(1).reduce((acc, item) => {
          const [key, type, encoding, value] = item
          acc.push({ key, type, encoding, value })
          return acc
        }, [])
      }

      reduxDispatch(createDevice(params))
    }

    reduxDispatch(nextStep())
  }

  function onClickPreviousStep(e) {
    e.preventDefault()
    reduxDispatch(previousStep())
  }

  function onRestartSteps(e) {
    e.preventDefault()
    reduxDispatch(restartSteps())
    reduxDispatch(cleanDevice())
    setFlashFinished(false)
    setHasPrinted(false)
  }

  function onFlashFinish() {
    setFlashFinished(true)
  }

  function onHasPrinted() {
    setHasPrinted(true)
  }

  function shouldDisableButton() {
    if (webSerialNotSupported) {
      return true
    }

    if (!initSuccess) {
      return true
    }

    if (currentStep.id === 'flash-device') {
      return !flashFinished
    }

    if (currentStep.id === 'print-code') {
      return !hasPrinted
    }

    return false
  }

  const disableNextButton = shouldDisableButton()

  return (
    <Fragment>
      <Steps />
      <StepTitle title={currentStep?.name} />
      <StepRouter
        currentStep={currentStep}
        onFlashFinish={onFlashFinish}
        onHasPrinted={onHasPrinted}
      />
      <div className='flex gap-2'>
        {currentStep.id === 'summary' && (
          <button
            className={`px-4 py-2 mt-4 bg-teal-400 text-black rounded hover:bg-teal-500 font-bold text-sm flex gap-2 justify-center ${
              disableNextButton ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={disableNextButton}
            onClick={onClickPreviousStep}
          >
            {I18n.get('Previous')}
            <ArrowLongLeftIcon className='h-5 w-5 self-center' />
          </button>
        )}
        <button
          className={`px-4 py-2 mt-4 bg-teal-400 text-black rounded hover:bg-teal-500 font-bold text-sm flex gap-2 justify-center ${
            disableNextButton ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={disableNextButton}
          onClick={
            currentStep.id === 'summary' ? onRestartSteps : onClickNextStep
          }
        >
          {currentStep.id === 'summary'
            ? I18n.get('Restart')
            : I18n.get('Next')}
          {currentStep.id !== 'summary' ? (
            <ArrowLongRightIcon className='h-5 w-5 self-center' />
          ) : (
            <ArrowPathIcon className='h-5 w-5 self-center' />
          )}
        </button>
      </div>
      {loading && <Loader text={I18n.get('Creating device files')} />}
    </Fragment>
  )
}

export default EspTool
