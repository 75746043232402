import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import DeviceTypeOptions from './DeviceTypeOptions'
import HardwareOptions from './HardwareOptions'
import NvsPartitionTemplate from './NvsPartitionTemplate'

import {
  selectDeviceType,
  selectDeviceTypeHardware,
  updateNvsPartition
} from '../../../actions/device'
import {
  getDeviceTypes,
  getNvsPartitionTemplate,
  getNvsPartition
} from '../../../reducers/selectors'

const StepChooseType = () => {
  const dispatch = useDispatch()

  const deviceTypes = getDeviceTypes()
  const nvsPartitionTemplate = getNvsPartitionTemplate()
  const nvsPartition = getNvsPartition()

  const [selectedType, setSelectedType] = useState(null)
  const [selectedHardware, setSelectedHardware] = useState(null)

  useEffect(() => {
    if (deviceTypes.length > 0) {
      setSelectedType(deviceTypes[0])
      setSelectedHardware(deviceTypes[0].hardware[0])
      dispatch(selectDeviceType(deviceTypes[0]))
      dispatch(selectDeviceTypeHardware(deviceTypes[0].hardware[0]))
    }
  }, [deviceTypes, dispatch])

  useEffect(() => {
    if (nvsPartitionTemplate.length > 0) {
      dispatch(updateNvsPartition(nvsPartitionTemplate))
    }
  }, [nvsPartitionTemplate, dispatch])

  function onSelectType(value) {
    setSelectedType(value)
    setSelectedHardware(value?.hardware[0])
    dispatch(selectDeviceType(value))
    dispatch(selectDeviceTypeHardware(value?.hardware[0]))
    dispatch(updateNvsPartition(nvsPartitionTemplate))
  }

  function onSelectHardware(value) {
    setSelectedHardware(value)
    dispatch(selectDeviceTypeHardware(value))
  }

  if (deviceTypes.length === 0) {
    return null
  }

  return (
    <Fragment>
      <DeviceTypeOptions
        selectedType={selectedType}
        onSelectType={onSelectType}
      />
      {selectedHardware && (
        <HardwareOptions
          selectedType={selectedType}
          selectedHardware={selectedHardware}
          onSelectHardware={onSelectHardware}
        />
      )}
      <NvsPartitionTemplate
        selectedType={selectedType}
        nvsPartition={nvsPartition}
      />
    </Fragment>
  )
}

export default StepChooseType
