import { API, graphqlOperation } from 'aws-amplify'

import { API_NAME, GRAPHQL_ROUTE } from '../config'
import { getIdToken } from '../utils'

export async function getCurrentUser() {
  try {
    const query = /* GraphQL */ `
      query getCurrentUser {
        getCurrentUser {
          supplierId
          userId
          type
          email
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, {})
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return { user: response.data.getCurrentUser, error: null }
  } catch (err) {
    return { user: null, error: err.message }
  }
}

export async function getUsers(supplierId) {
  try {
    const query = /* GraphQL */ `
      query getUsers($supplierId: String!) {
        getUsers(supplierId: $supplierId) {
          supplierId
          userId
          email
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, { supplierId })
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return { users: response.data.getUsers, error: null }
  } catch (err) {
    return { users: null, error: err.message }
  }
}

export async function createSupplierUser(params) {
  try {
    const query = /* GraphQL */ `
      mutation createSupplierUser(
        $supplierId: String!
        $email: String!
        $type: String!
      ) {
        createSupplierUser(
          supplierId: $supplierId
          email: $email
          type: $type
        ) {
          supplierId
          userId
          email
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return { user: response.data.createSupplierUser, error: null }
  } catch (err) {
    return { user: null, error: err.message }
  }
}

export async function deleteSupplierUser(params) {
  try {
    const query = /* GraphQL */ `
      mutation deleteSupplierUser($supplierId: String!, $userId: String!) {
        deleteSupplierUser(supplierId: $supplierId, userId: $userId) {
          supplierId
          userId
        }
      }
    `
    const jwtToken = await getIdToken()
    const operation = graphqlOperation(query, params)
    const response = await API.post(API_NAME, GRAPHQL_ROUTE, {
      body: operation,
      headers: {
        Authorization: jwtToken
      }
    })

    if (response.errors) {
      throw Error(response.errors[0].message)
    }

    return { user: response.data.deleteSupplierUser, error: null }
  } catch (err) {
    return { user: null, error: err.message }
  }
}
