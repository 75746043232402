import { Fragment } from 'react'

import StepChooseType from './StepChooseType'
import StepFlash from './StepFlash'
import StepQR from './StepQR'
import StepSummary from './StepSummary'

const router = {
  'create-files': func => <StepChooseType onSelectDeviceType={func} />,
  'flash-device': func => <StepFlash onFlashFinish={func} />,
  'print-code': func => <StepQR onHasPrinted={func} />,
  summary: () => <StepSummary />
}

const StepRouter = ({ currentStep, onFlashFinish, onHasPrinted }) => {
  const routerFunctions = {
    'create-files': null,
    'flash-device': onFlashFinish,
    'print-code': onHasPrinted,
    summary: null
  }

  return (
    <Fragment>
      {router[currentStep.id]
        ? router[currentStep.id](routerFunctions[currentStep.id])
        : null}
    </Fragment>
  )
}

export default StepRouter
