import { makeActionCreator } from '../../utils/redux'

export const USER_FETCHED = 'USER_FETCHED'
export const userFetched = makeActionCreator(USER_FETCHED, 'user', 'error')

export const USERS_FETCHED = 'USERS_FETCHED'
export const usersFetched = makeActionCreator(USERS_FETCHED, 'users', 'error')

export const CREATE_USER = 'CREATE_USER'
export const USER_CREATED = 'USER_CREATED'
export const createUser = makeActionCreator(CREATE_USER, 'params')
export const userCreated = makeActionCreator(USER_CREATED, 'user', 'error')

export const DELETE_USER = 'DELETE_USER'
export const USER_DELETED = 'USER_DELETED'
export const deleteUser = makeActionCreator(DELETE_USER, 'params')
export const userDeleted = makeActionCreator(USER_DELETED, 'user', 'error')
