import { useRoutes } from 'react-router'

import App from './pages/App'
import EspTool from './pages/EspTool'
import Users from './pages/Users'

const Routes = () => {
  const route = useRoutes([
    {
      path: '/',
      element: <App />,
      children: [
        {
          path: '/esptool',
          element: <EspTool />
        },
        {
          path: '/users',
          element: <Users />
        }
      ]
    }
  ])

  return route
}

export default Routes
