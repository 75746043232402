import { createReducer } from '../../utils/redux'

import {
  USER_FETCHED,
  USERS_FETCHED,
  USER_CREATED,
  USER_DELETED
} from '../../actions/user'

const initialState = {
  user: {},
  users: []
}

const handler = {
  [USER_FETCHED]: (state, action) => ({
    ...state,
    user: !action.error ? action.user : {}
  }),
  [USERS_FETCHED]: (state, action) => ({
    ...state,
    users: !action.error ? action.users : []
  }),
  [USER_CREATED]: (state, action) => ({
    ...state,
    users: !action.error ? [...state.users, action.user] : state.users
  }),
  [USER_DELETED]: (state, action) => ({
    ...state,
    users: !action.error
      ? state.users.filter(user => user.userId !== action.user.userId)
      : state.users
  })
}

export const userReducer = createReducer(handler, initialState)
