import { Fragment, useState } from 'react'
import { I18n } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, UserIcon } from '@heroicons/react/24/outline'

import { createUser } from '../../actions/user'

import { getUser } from '../../reducers/selectors'

export default function AddModal({ onCloseModal }) {
  const dispatch = useDispatch()
  const currentUser = getUser()

  const [email, setEmail] = useState('')

  function onChangeInput(e) {
    setEmail(e.target.value)
  }

  function onClickCreate() {
    const { supplierId } = currentUser
    dispatch(createUser({ supplierId, email, type: 'staff' }))
    onCloseModal()
  }

  function onClose() {
    onCloseModal()
  }

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 w-full max-w-lg p-6'>
                <div className='absolute right-0 top-0 hidden pr-4 pt-4 sm:block'>
                  <button
                    type='button'
                    className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2'
                    onClick={onClose}
                  >
                    <span className='sr-only'>{I18n.get('Close')}</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10'>
                    <UserIcon
                      className='h-6 w-6 text-teal-600'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-normal leading-6 text-gray-900'
                    >
                      {I18n.get('Add user')}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <form onSubmit={onClickCreate}>
                        <div>
                          <label
                            htmlFor='username'
                            className='block text-sm font-bold leading-6 text-gray-900'
                          >
                            {I18n.get('Email')}
                          </label>
                          <div className='mt-2'>
                            <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-600 sm:max-w-md'>
                              <input
                                type='email'
                                name='email'
                                id='email'
                                value={email}
                                onChange={onChangeInput}
                                className='block flex-1 border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                                placeholder='email@company.com'
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                  <button
                    type='submit'
                    className='inline-flex w-full justify-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 sm:ml-3 sm:w-auto'
                    onClick={onClickCreate}
                  >
                    {I18n.get('Create')}
                  </button>
                  <button
                    type='button'
                    className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                    onClick={onClose}
                  >
                    {I18n.get('Cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
