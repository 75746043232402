/* eslint-disable react-hooks/rules-of-hooks */

import { useSelector } from 'react-redux'

const getStateWithSelector = (reducer, value) => {
  return useSelector(state => state.rootReducer[reducer][value])
}

// Device reducer
export const getNvsPartitionTemplate = () => {
  return getStateWithSelector('deviceReducer', 'nvsPartitionTemplate')
}

export const getNvsPartition = () => {
  return getStateWithSelector('deviceReducer', 'nvsPartition')
}

export const getDeviceTypes = () => {
  return getStateWithSelector('deviceReducer', 'deviceTypes')
}

export const getDevice = () => {
  return getStateWithSelector('deviceReducer', 'device')
}

export const getDeviceLoading = () => {
  return getStateWithSelector('deviceReducer', 'loading')
}

export const getSelectedDeviceType = () => {
  return getStateWithSelector('deviceReducer', 'selectedDeviceType')
}

export const getSelectedHardware = () => {
  return getStateWithSelector('deviceReducer', 'selectedHardare')
}

export const getPreviousSteps = () => {
  return getStateWithSelector('deviceReducer', 'previousSteps')
}

export const getCurrentStep = () => {
  return getStateWithSelector('deviceReducer', 'currentStep')
}

// Supplier reducer
export const getSupplier = () => {
  return getStateWithSelector('supplierReducer', 'supplier')
}

// User reducer
export const getUser = () => {
  return getStateWithSelector('userReducer', 'user')
}

export const getUsers = () => {
  return getStateWithSelector('userReducer', 'users')
}

// Util reducer
export const getBannerErrors = () => {
  return getStateWithSelector('utilReducer', 'errors')
}

export const getBannerSuccessMsg = () => {
  return getStateWithSelector('utilReducer', 'success')
}

export const getUtilLoading = () => {
  return getStateWithSelector('utilReducer', 'loading')
}

export const getWebSerialNotSupported = () => {
  return getStateWithSelector('utilReducer', 'webSerialNotSupported')
}

export const getInitSuccess = () => {
  return getStateWithSelector('utilReducer', 'initSuccess')
}
