const FORM_WRAPPER_CLASS =
  'w-full m-auto max-w-lg mx-auto py-8 px-10 rounded bg-white border border-gray-200 shadow sm:rounded-lg'

function FormWrapper({ onSubmit, children }) {
  return (
    <form className={FORM_WRAPPER_CLASS} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

export default FormWrapper
