import { call, put, takeEvery } from 'redux-saga/effects'

import {
  CREATE_USER,
  userCreated,
  DELETE_USER,
  userDeleted
} from '../../actions/user'

import { setErrors } from '../../actions/util'

import { createSupplierUser, deleteSupplierUser } from '../../api/user'

function* sendCreateUser(action) {
  const { user, error } = yield call(createSupplierUser, action.params)

  yield put(userCreated(user, error))

  if (error) {
    yield put(
      setErrors(['Something went wrong creating the user. Please try again.'])
    )
  }
}

export function* watchCreateUser() {
  yield takeEvery(CREATE_USER, sendCreateUser)
}

function* sendDeleteUser(action) {
  const { user, error } = yield call(deleteSupplierUser, action.params)

  yield put(userDeleted(user, error))

  if (error) {
    yield put(
      setErrors(['Something went wrong creating the user. Please try again.'])
    )
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, sendDeleteUser)
}
